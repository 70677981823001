import React, { useEffect, useState } from "react";
import logo from "./logo.png";
import axios from "axios";

const Footer = () => {
  const [data, setdata] = useState([]);
  const fetchData = async () => {
    const response = await axios.get(`https://justmindwork.com/api/services`);
    setdata(response.data);
    console.log("response", response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <footer className="site-footer section bg-dark text-contrast edge top-left">
      <div className="container py-3">
        <div className="row gap-y text-center text-md-left">
          <div className="col-md-12 mr-auto pt-5">
            <img src={logo} alt="" className="logo" />

            <p>
              Welcome to our established web application and Mobile App
              development company, where we specialize in Ruby on Rails and
              React also serving businesses across all sectors. By utilizing the
              power of the latest technologies and frameworks, combined with our
              extensive experience, we deliver customized, reliable, and
              efficient solutions that are designed to meet your specific needs.
              Trust us to bring your digital vision to life and elevate your
              online presence to new heights.
            </p>
            <hr className="mt-5 op-5" />
          </div>

          <div className="col-xl-auto col-lg-auto col-md-6 col-sm-12">
            <nav className="nav flex-column">
              <h4>Our Services</h4>
              {data.map((location, index) => (
                <a
                  className="nav-item py-2 text-contrast"
                  href={`/servicedetails/${location.slug}`}
                >
                  {location.title}
                </a>
              ))}
            </nav>
          </div>

          <div className="col-xl-auto col-lg-auto col-md-6 col-sm-12">
            <nav className="nav flex-column">
              <h4>Services</h4>
              <a className="nav-item py-2 text-contrast" href="#">
                Back-End
              </a>
              <a className="nav-item py-2 text-contrast" href="#">
                Front -End
              </a>
              <a className="nav-item py-2 text-contrast" href="#">
                Database
              </a>
              <a className="nav-item py-2 text-contrast" href="#">
                Cloud
              </a>
              <a className="nav-item py-2 text-contrast" href="#">
                API
              </a>
              <a className="nav-item py-2 text-contrast" href="#">
                Testing
              </a>
            </nav>
          </div>

          <div className="col-xl-auto col-lg-auto col-md-6 col-sm-12">
            <nav className="nav flex-column">
              <h4>Industries</h4>
              <a className="nav-item py-2 text-contrast">
                Information & Technology
              </a>
              <a className="nav-item py-2 text-contrast">FinTech</a>
              <a className="nav-item py-2 text-contrast">Retail & Ecommerce</a>
              <a className="nav-item py-2 text-contrast">
                Travel & Hospotality
              </a>
              <a className="nav-item py-2 text-contrast">Healtcare</a>
              <a className="nav-item py-2 text-contrast">EdTech</a>
            </nav>
          </div>

          <div className="col-xl-auto col-lg-auto col-md-6 col-sm-12">
            <nav className="nav flex-column">
              <h4>Hire Remote Developers</h4>
              <a className="nav-item py-2 text-contrast">Ruby on Rails</a>
              <a className="nav-item py-2 text-contrast">React Native</a>
              <a className="nav-item py-2 text-contrast">React.Js</a>
              <a className="nav-item py-2 text-contrast">MERN</a>
              <a className="nav-item py-2 text-contrast">Python,and more...</a>
            </nav>
          </div>

          <div className="col">
            <h4 className="text-white "> Contact US</h4>
            <p className="mt-2 mb-0 text-left">
              407 , Satguru Parinay, Scheme 54,opposite C21 Mall, PU4, AB
              Road,Indore, Madhya Pradesh 452011
            </p>

            <h4 className="text-white mt-3">Mail us</h4>
            <p>business@justmindwork.com | sales@justmindwork.com</p>

            <h4 className="text-white mt-3">Call Us</h4>
            <p>+91-96447-80306</p>
            <h4 className="text-white mt-3">Follow us</h4>

            <nav className="nav">
              <a
                href="https://facebook.com/5studios.net"
                target="_blank"
                className="btn btn-circle mr-3 btn-sm brand-facebook"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" className="btn btn-circle mr-3 btn-sm brand-twitter">
                <i className="fab fa-twitter"></i>
              </a>
              {/* <a
                href="#"
                className="btn btn-circle mr-3 btn-sm brand-instagram"
              >
                <i className="fab fa-instagram"></i>
              </a> */}
              <a
                href="https://www.linkedin.com/company/justmindwork/"
                className="btn btn-circle mr-3 btn-sm brand-instagram"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </nav>
          </div>
        </div>

        <hr className="mt-5 op-5" />
        <div className="row small justify-content-center">
          <div className="col-md-4">
            <p className="mt-2 mb-0 text-center">
              © 2024 <a href="https://justmindwork.com/">Just Mind Work</a>. All
              Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
