import React, { useEffect, useState } from "react";
import port_01 from "./img/portfolio/port_01.jpg";
import port_02 from "./img/portfolio/port_02.jpg";
import port_03 from "./img/portfolio/port_03.jpg";
import port_04 from "./img/portfolio/port_04.jpg";
import port_05 from "./img/portfolio/port_05.jpg";
import port_06 from "./img/portfolio/port_06.jpg";
import port_single_02 from "./img/portfolio/port_single_02.jpg";
import port_single_03 from "./img/portfolio/port_single_03.jpg";
import port_single from "./img/portfolio/port_single.jpg";
import Isotope from "isotope-layout";
import axios from "axios";
import { Link } from "react-router-dom";


const Portfolio = () => {
  const [data, setdata] = useState([]);
  const fetchData = async () => {
    const response = await axios.get(`https://justmindwork.com/api/portfolios`);
    setdata(response.data);
    console.log("response", response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [selectedPortfolioType, setSelectedPortfolioType] = useState("all"); // 'all' or any default type

  // Function to handle the portfolio type change
  const handleFilterClick = (portfolioType) => {
    setSelectedPortfolioType(portfolioType);
  };

  // Filter data based on the selected portfolio type
  const filteredData =
    selectedPortfolioType === "all"
      ? data
      : data.filter(
          (location) => location.portfolio_type === selectedPortfolioType
        );
  // // init one ref to store the future isotope object
  // const isotope = React.useRef();
  // // store the filter keyword in a state
  // const [filterKey, setFilterKey] = React.useState("*");

  // // initialize an Isotope object with configs
  // React.useEffect(() => {
  //   isotope.current = new Isotope(".filter-container", {
  //     itemSelector: ".filter-item",
  //     layoutMode: "fitRows",
  //     transitionDuration: "0.5s", // Add transition duration
  //   });

  //   // cleanup
  //   return () => isotope.current.destroy();
  // }, []);

  // // handling filter key change
  // React.useEffect(() => {
  //   if (isotope.current) {
  //     isotope.current.updateSortData();
  //   }
  // }, [data]);

  // React.useEffect(() => {
  //   if (isotope.current) {
  //     filterKey === "*"
  //       ? isotope.current.arrange({ filter: `*` })
  //       : isotope.current.arrange({ filter: `.${filterKey}` });
  //   }
  // }, [filterKey]);

  // const handleFilterKeyChange = (key) => () => setFilterKey(key);
  return (
    <main>
      {/* breadcrumb start */}
      <section
        className="breadcrumb bg_img d-flex align-items-center dark-2"
        data-overlay="dark-2"
        data-opacity={7}
      >
        <div className="container p-0">
          <div className="breadcrumb__content text-center">
            <h2>Portfolio</h2>
            <ul className="bread-crumb clearfix ul_li_center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">Portfolio</li>
            </ul>
          </div>
        </div>
      </section>
      {/* breadcrumb end */}
      {/* portfolio start */}
      <section className="portfolio pt-120 pb-120">
        <div className="container p-0">
          <div className="row align-items-end mb-35">
            <div className="col-lg-7">
              <div className="section-heading mb-20">
                <div className="heading-subtitle wow fadeInRight">
                  <img src="assets/img/icon/sh_icon.png" alt="" /> Our best
                  <span>portfolio</span>
                </div>
                <h3 className="heading-title tx-split-text split-in-right">
                  Our portfolio
                </h3>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="section-heading mb-20">
                <p>
                  We’ve grown up with the internet revolution, and we know how
                  to deliver on its promise of improved business
                </p>
              </div>
            </div>
          </div>
          {/* <div className="portfolio__menu mb-35">
            
            <button onClick={handleFilterKeyChange("*")}>SEE ALL</button>
            <button onClick={handleFilterKeyChange("cat1")}>BUSINESS</button>
            <button onClick={handleFilterKeyChange("cat2")}>DEVELOPMENT</button>
            <button onClick={handleFilterKeyChange("cat3")}>WEB DESIGN</button>
            <button onClick={handleFilterKeyChange("cat4")}>
              UI/UX DESIGN
            </button>
            <button onClick={handleFilterKeyChange("cat5")}>TECHNOLOGY</button>
          </div> */}

          <div className="filter-container row">
            <div className="row grid">
              {data.map((location, index) => (
                <div
                  className={`col-lg-4 col-md-6 col-sm-6 col-12 filter-item ${location.portfolio_type}`}
                  key={index}
                >
                  <a href={`/portfoliodetails/${location.slug}`}>
                    <div className="portfolio__item mb-30">
                      <div className="portfolio__img">
                        <img src={location.image.url} alt="" />
                      </div>
                      <div className="portfolio__content">
                        <h3 className="title">
                          <a href={`/portfoliodetails/${location.slug}`}>
                            {location.title}
                          </a>
                        </h3>
                        <a className="category" href="#!">
                          Business
                        </a>
                      </div>
                      <a
                        className="portfolio__link"
                        href={`/portfoliodetails/${location.slug}`}
                      >
                        +
                      </a>
                    </div>
                  </a>
                </div>
              ))}
              {/* 
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 filter-item  cat1 cat3">
                <div className="portfolio__item mb-30">
                  <div className="portfolio__img">
                    <img src={port_01} alt="" />
                  </div>
                  <div className="portfolio__content">
                    <h3 className="title">
                      <a href="/portfoliosingles">{data?.title}</a>
                    </h3>
                    <a className="category" href="#!">
                      Business
                    </a>
                  </div>
                  <a className="portfolio__link" href="/portfoliosingles">
                    +
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 filter-item  cat4 cat5 cat2">
                <div className="portfolio__item mb-30">
                  <div className="portfolio__img">
                    <img src={port_02} alt="" />
                  </div>
                  <div className="portfolio__content">
                    <h3 className="title">
                      <a href="/portfoliosingles">Startup Business</a>
                    </h3>
                    <a className="category" href="#!">
                      Business
                    </a>
                  </div>
                  <a className="portfolio__link" href="/portfoliosingles">
                    +
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 filter-item  cat4 cat2 cat3">
                <div className="portfolio__item mb-30">
                  <div className="portfolio__img">
                    <img src={port_03} alt="" />
                  </div>
                  <div className="portfolio__content">
                    <h3 className="title">
                      <a href="/portfoliosingles">Startup Business</a>
                    </h3>
                    <a className="category" href="#!">
                      Business
                    </a>
                  </div>
                  <a className="portfolio__link" href="/portfoliosingles">
                    +
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 filter-item  cat1 cat2 cat4">
                <div className="portfolio__item mb-30">
                  <div className="portfolio__img">
                    <img src={port_04} alt="" />
                  </div>
                  <div className="portfolio__content">
                    <h3 className="title">
                      <a href="/portfoliosingles">Startup Business</a>
                    </h3>
                    <a className="category" href="#!">
                      Business
                    </a>
                  </div>
                  <a className="portfolio__link" href="/portfoliosingles">
                    +
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 filter-item  cat3 cat5 cat2">
                <div className="portfolio__item mb-30">
                  <div className="portfolio__img">
                    <img src={port_05} alt="" />
                  </div>
                  <div className="portfolio__content">
                    <h3 className="title">
                      <a href="/portfoliosingles">Startup Business</a>
                    </h3>
                    <a className="category" href="#!">
                      Business
                    </a>
                  </div>
                  <a className="portfolio__link" href="/portfoliosingles">
                    +
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 filter-item  cat5 cat1 cat4">
                <div className="portfolio__item mb-30">
                  <div className="portfolio__img">
                    <img src={port_06} alt="" />
                  </div>
                  <div className="portfolio__content">
                    <h3 className="title">
                      <a href="/portfoliosingles">Startup Business</a>
                    </h3>
                    <a className="category" href="#!">
                      Business
                    </a>
                  </div>
                  <a className="portfolio__link" href="/portfoliosingles">
                    +
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <div className="portfolio__button text-center mt-20">
            <a href="/contact" className="thm-btn thm-btn__gradient">
              <span className="btn-anim-wrap">
                <span className="button-text">Contact Us</span>
                <span className="button-text">Contact Us</span>
              </span>
            </a>
          </div>
        </div>
      </section>
      {/* portfolio end */}
      {/* cta start */}
      
      {/* cta start */}
    </main>
  );
};

export default Portfolio;
