import React from "react";
import "./css/Main.css";
import "./css/flaticon.css";
import aobut_img3 from "./img/about/aobut_img3.jpg";
import img_10 from "./img/team/img_05.png";
import img_11 from "./img/team/img_06.png";
import img_12 from "./img/team/img_07.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Testimonials from "./Testimonials";
import Servicesbox from "./Servicesbox";
import CountUp from "react-countup";


const About = () => {
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <main>
      {/* breadcrumb start */}
      <section
        className="breadcrumb bg_img d-flex align-items-center dark-2"
        data-overlay="dark-2"
        data-opacity={7}
      >
        <div className="container p-0">
          <div className="breadcrumb__content text-center">
            <h2>About us</h2>
            <ul className="bread-crumb clearfix ul_li_center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">About Us</li>
            </ul>
          </div>
        </div>
      </section>
      {/* breadcrumb end */}
      {/* about start */}
      <section className="about overflow-hidden pos-rel pt-120 pb-120">
        <div className="container p-0">
          <div className="row mt-none-30 align-items-center">
            <div className="col-lg-6 mt-30">
              <div className="about__banner">
                <div className="about__banner-img feature-img-animation active">
                  <div className="image">
                    <img src={aobut_img3} alt="" />
                  </div>
                </div>
                <div className="about__banner-content about__banner-content--2 bg_img">
                  <div className="about__banner-title">
                    <span>Best IT Solutions</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-30">
              <div className="about__content-2">
                <div className="section-heading mb-30">
                  <div className="heading-subtitle wow fadeInRight">
                    <img src="assets/img/icon/sh_icon.png" alt="" /> How JMW
                    Began
                  </div>
                  <h3 className="heading-title tx-split-text split-in-right mb-30">
                    Choose The Best IT Service Company in the India.
                  </h3>
                  <p>
                    The journey started when two software engineers decided to
                    take the leap and start their own IT company named just mind
                    work & founded in 2021. We specialize in custom software,
                    web Application and mobile app development, product
                    creating, digital consultancy, and testing to drive your
                    business growth.
                  </p>
                </div>

                <ul
                  className="about__tab nav nav-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      <span>Our Mission</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      <span>Our Vision</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      <span>Our Value</span>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane animate__animated animate__fadeInUp show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="about__tab-content">
                      JMW has a vision — to be a leading web solution company in
                      the IT sector. Our commitment is to the growth of our
                      customers because we firmly believe that their success is
                      our success. We aspire to be recognized as a reliable,
                      innovative, and user-friendly software service provider in
                      the IT industry.
                    </div>
                  </div>
                  <div
                    className="tab-pane animate__animated animate__fadeInUp"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="about__tab-content">
                      JMW has a clear mission — to exceed our clients'
                      expectations by providing web solutions that go beyond
                      software. We transform data into knowledge, enabling our
                      clients to solve their problems effectively.
                    </div>
                  </div>
                  <div
                    className="tab-pane animate__animated animate__fadeInUp"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="about__tab-content">
                      Our Values"JMW stands committed to leading the IT sector
                      by prioritizing customer growth as our own success. With a
                      vision to be a trusted, innovative Software solution
                      provider, we transform data into actionable insights,
                      consistently exceeding client expectations."
                    </div>
                  </div>
                </div>
                <a href="/contact" className="thm-btn thm-btn__gradient mt-30">
                  <span className="btn-anim-wrap">
                    <span className="button-text">Contact Us</span>
                    <span className="button-text">Contact Us</span>
                  </span>
                  <i className="flaticon-top-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about end */}
      {/* counter start */}
      <section
        className="counter pos-rel jarallax pt-120 pb-120 dark-3"
        data-background="assets/img/bg/counter_bg.jpg"
      >
        <div className="container p-0">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-heading section-heading--white text-center mb-70">
                <div className="heading-subtitle wow fadeInRight">
                  <img src="assets/img/icon/sh_icon.png" alt="" /> We have a
                  great <span>achievement</span>
                </div>
                <h3 className="heading-title tx-split-text split-in-up">
                  Our best achievement!
                </h3>
              </div>
            </div>
          </div>
          <div className="row mt-none-30 justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-6 mt-30">
              <div
                className="counter__item text-center wow fadeInRight"
                data-wow-delay="ms"
              >
                <div className="icon">
                  <i className="flaticon-like" />
                </div>
                <h2 className="counter__number">
                  <CountUp end={30} duration={5} />

                  <span className="suffix">+</span>
                </h2>
                <span className="title">Satisfied Customer</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-30">
              <div
                className="counter__item text-center wow fadeInRight"
                data-wow-delay="100ms"
              >
                <div className="icon">
                  <i className="flaticon-follower" />
                </div>
                <h2 className="counter__number">
                  <CountUp end={100} duration={5} />
                  <span className="suffix">+</span>
                </h2>
                <span className="title">Experience Team</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-30">
              <div
                className="counter__item text-center wow fadeInRight"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i className="flaticon-rocket" />
                </div>
                <h2 className="counter__number">
                  <CountUp end={50} duration={5} />

                  <span className="suffix">+</span>
                </h2>
                <span className="title">Project Completed</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* counter end */}
      {/* searvice start */}
      <Servicesbox />
      {/* searvice end */}
      {/* team start */}
      <section className="team bg-deep pos-rel pt-60 pb-110">
        <div
          className="team__bg-img jarallax"
          data-background="assets/img/bg/team_bg2.jpg"
        />
        <div className="container-fluid p-0">
          <div className="row team-wrap align-items-center mt-none-30">
            <div className="col-xl-4 col-lg-5 mt-30">
              <div className="team__content-area">
                <div className="section-heading section-heading--md section-heading--white">
                  <h3 className="heading-title tx-split-text split-in-right mb-30">
                    Our Awesome Team members
                  </h3>
                  <div className="section-heading--content style3">
                    <p>
                      Our exceptional team of developers stands at the forefront
                      of innovation, bringing expertise and dedication to every
                      project. With a blend of technical prowess and
                      collaborative spirit, they drive our IT company's
                      success."
                    </p>
                  </div>
                  <a href="/contact" className="thm-btn thm-btn__gradient mt-40">
                    <span className="btn-anim-wrap">
                      <span className="button-text">Let’s Partner up!</span>
                      <span className="button-text">Let’s Partner up!</span>
                    </span>
                    <i className="flaticon-top-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 mt-30">
              <div className="team__carousel team__carousel-2 swiper-container">
                <Slider {...settings1} className="swiper-wrapper">
                  <div className="team__single swiper-slide">
                    <div className="team-image">
                      <img src={img_10} alt="" />
                    </div>
                    <span className="name">Albert Morich</span>
                    <ul className="team-social ul_li">
                      <li>
                        <a href="#!">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-behance" />
                        </a>
                      </li>
                    </ul>
                    <div className="team-info">
                      <h3>Albert Morich</h3>
                      <span>UI/UX Designer</span>
                    </div>
                    <a className="team-link" href="#!">
                      <i className="fas fa-plus" />
                    </a>
                  </div>
                  <div className="team__single swiper-slide">
                    <div className="team-image">
                      <img src={img_11} alt="" />
                    </div>
                    <span className="name">Albert Morich</span>
                    <ul className="team-social ul_li">
                      <li>
                        <a href="#!">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-behance" />
                        </a>
                      </li>
                    </ul>
                    <div className="team-info">
                      <h3>Albert Hippo</h3>
                      <span>UI/UX Designer</span>
                    </div>
                    <a className="team-link" href="#!">
                      <i className="fas fa-plus" />
                    </a>
                  </div>
                  <div className="team__single swiper-slide">
                    <div className="team-image">
                      <img src={img_12} alt="" />
                    </div>
                    <span className="name">Albert Morich</span>
                    <ul className="team-social ul_li">
                      <li>
                        <a href="#!">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-behance" />
                        </a>
                      </li>
                    </ul>
                    <div className="team-info">
                      <h3>Fransis Bart</h3>
                      <span>UI/UX Designer</span>
                    </div>
                    <a className="team-link" href="#!">
                      <i className="fas fa-plus" />
                    </a>
                  </div>
                  <div className="team__single swiper-slide">
                    <div className="team-image">
                      <img src={img_10} alt="" />
                    </div>
                    <span className="name">Albert Morich</span>
                    <ul className="team-social ul_li">
                      <li>
                        <a href="#!">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-behance" />
                        </a>
                      </li>
                    </ul>
                    <div className="team-info">
                      <h3>Albert Morich</h3>
                      <span>UI/UX Designer</span>
                    </div>
                    <a className="team-link" href="#!">
                      <i className="fas fa-plus" />
                    </a>
                  </div>
                  <div className="team__single swiper-slide">
                    <div className="team-image">
                      <img src={img_11} alt="" />
                    </div>
                    <span className="name">Albert Morich</span>
                    <ul className="team-social ul_li">
                      <li>
                        <a href="#!">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-behance" />
                        </a>
                      </li>
                    </ul>
                    <div className="team-info">
                      <h3>Albert Hippo</h3>
                      <span>UI/UX Designer</span>
                    </div>
                    <a className="team-link" href="#!">
                      <i className="fas fa-plus" />
                    </a>
                  </div>
                  <div className="team__single swiper-slide">
                    <div className="team-image">
                      <img src={img_12} alt="" />
                    </div>
                    <span className="name">Albert Morich</span>
                    <ul className="team-social ul_li">
                      <li>
                        <a href="#!">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fab fa-behance" />
                        </a>
                      </li>
                    </ul>
                    <div className="team-info">
                      <h3>Fransis Bart</h3>
                      <span>UI/UX Designer</span>
                    </div>
                    <a className="team-link" href="#!">
                      <i className="fas fa-plus" />
                    </a>
                  </div>
                </Slider>
              </div>
              {/* <div className="team__carousel team__carousel-2 swiper-container">
                <div className="team-carousel-button">
                  <div className="team-button-prev">
                    <i className="fas fa-long-arrow-left" />
                  </div>
                  <div className="team-button-next">
                    <i className="fas fa-long-arrow-right" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* team end */}
      {/* testimonial start */}
      {/* <Testimonials /> */}
      {/* testimonial end */}
      {/* cta start */}
          
      {/* cta start */}
    </main>
  );
};

export default About;
