import React, { useEffect, useState } from "react";
import service_single_bg from "./img/bg/service_single_bg.jpg";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

import axios from "axios";

const ServicesSingle = () => {
  const [data1, setdata1] = useState([]);
  const fetchData1 = async () => {
    const response = await axios.get(`https://justmindwork.com/api/services`);
    setdata1(response.data);
    console.log("response", response.data);
  };

  useEffect(() => {
    fetchData1();
  }, []);

  const { slug } = useParams();
  console.log(slug);
  const [data, setdata] = useState([]);

  const fetchData = async () => {
    const response = await axios.get(
      `https://justmindwork.com/api/services/${slug}`
    );
    setdata(response.data);
    console.log("wholw daTA", response.data);
  };

  useEffect(() => {
    fetchData();
  }, [slug]);
  return (
    <main>
      {/* breadcrumb start */}

      <section
        className="breadcrumb bg_img d-flex align-items-center dark-2"
        data-overlay="dark-2"
        data-opacity={7}
      >
        <div className="container p-0">
          <div className="breadcrumb__content text-center">
            <h2>Services Details</h2>
            <ul className="bread-crumb clearfix ul_li_center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">Services Details</li>
            </ul>
          </div>
        </div>
      </section>

      {/* breadcrumb end */}
      {/* service single start */}
      <section className="service-single pt-120 pb-120">
        <div className="container p-0">
          <div className="row mt-none-30">
            <div className="col-lg-8 mt-30">
              <div className="service-single__content">
                <div className="service-single__thumb mb-30">
                  <img src={data?.image?.url} className="w-full" alt="" />
                </div>


                {/* {parse(data?.description?.body)} */}
              </div>
            </div>
            <div className="col-lg-4 mt-30">
              <div className="sidebar-area mt-none-30">
                <div className="widget mt-30">
                  <h2 className="widget__title">
                    <span>Our Services</span>
                  </h2>
                  <div className="widget__inner">
                    <ul className="widget__category list-unstyled">
                      {data1.map((location, index) => (
                        <li>
                          <a
                            className={location.slug === slug ? "active" : ""}
                            href={`/servicedetails/${location.slug}`}
                          >
                            {location.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="widget text-center mt-4 ">
                  <a href="/contact" className="thm-btn thm-btn__gradient ">
                    <span className="btn-anim-wrap">
                      <span className="button-text">Contact us</span>
                      <span className="button-text">Contact us</span>
                    </span>
                    <i className="flaticon-top-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h1 className="mb-3">{data?.title}</h1>

            <div className="content-managament">
              {typeof data?.description?.body === "string"
                ? parse(data?.description?.body)
                : null}
            </div>
          </div>
        </div>
      </section>
      {/* service single end */}
      {/* cta start */}


      {/* cta start */}
    </main>
  );
};

export default ServicesSingle;
