import React from "react";

const SubFooter = () => {
  return (
    <section className="cta cta__bg">
      <div className="container p-0">
        <div className="cta__wrap ul_li_between">
          <h2 className="cta__title">
            Looking for the Best IT Business Solutions?
          </h2>
          <div className="cta__btn">
            <a href="/contact" className="thm-btn thm-btn__gradient">
              <span className="btn-anim-wrap">
                <span className="button-text">Get a quote</span>
                <span className="button-text">Get a quote</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubFooter;
