import React from "react";
import "../Home/Home.css";

import device1 from "../Home/img/screens/tablet/1.png";
import device2 from "../Home/img/screens/app/4.png";
import device3 from "../Home/img/screens/app/2.png";
import device4 from "../Home/img/screens/tablet/2.png";

import logo1 from "../Home/img/logos/1.png";
import logo2 from "../Home/img/logos/2.png";
import logo3 from "../Home/img/logos/3.png";
import logo4 from "../Home/img/logos/4.png";
import logo5 from "../Home/img/logos/5.png";
import logo6 from "../Home/img/logos/6.png";

import pieces1 from "../Home/img/screens/app/pieces/4.png";
import pieces2 from "../Home/img/screens/app/pieces/5.png";
import pieces3 from "../Home/img/screens/app/6.png";

import pieces4 from "../Home/img/screens/app/pieces/1.png";
import pieces5 from "../Home/img/screens/app/pieces/2.png";
import pieces6 from "../Home/img/screens/app/pieces/3.png";

import apx from "../Home/img/demo/header/apx.svg";
import bs from "../Home/img/demo/header/bs.svg";
import cordova from "../Home/img/demo/header/cordova.svg";
import css from "../Home/img/demo/header/css.svg";
import fa from "../Home/img/demo/header/fa.svg";
import html from "../Home/img/demo/header/html.svg";
import js from "../Home/img/demo/header/js.svg";
import npm from "../Home/img/demo/header/npm.svg";
import sass from "../Home/img/demo/header/sass.svg";

import tablet1 from "../Home/img/screens/tablet/1.png";
import tablet2 from "../Home/img/screens/app/1.png";


import meeting_image from "../Home/img/image-058.jpg"
const Home = () => {
  return (
    <main>
      {/* Alternative 3 Heading */}
      <header
        className="header alter3-header section gradient gradient-primary-auxiliary text-contrast"
        id="home"
      >
        <div className="shapes-container">
          <div className="animation-shape shape-ring animation--rotating-diagonal">
            <div />
          </div>
          <div className="animation-shape shape-circle animation--clockwise">
            <div />
          </div>
          <div className="animation-shape shape-triangle animation--anti-clockwise">
            <div className="animation--rotating" />
          </div>
          <div className="animation-shape shape-diamond animation--anti-clockwise">
            <div className="animation--rotating" />
          </div>
          <div className="static-shape shape-ring-1" />
          <div className="static-shape shape-ring-2" />
          <div className="static-shape shape-circle-1" />
          <div className="static-shape pattern-dots-1" />
          <div className="static-shape pattern-dots-2" />
          <div className="static-shape ghost-shape ghost-shape-1" />
        </div>
        <div className="container bring-to-front">
          <div className="row">
            <div className="col-md-6">
              <h1 className="extra-bold display-md-4 font-md text-light">
                Next Generation
                <span className="d-block display-md-6 light">
                  Custom Software, Web Application, Mobile App, Product
                  Development
                </span>
              </h1>
              <p className="lead">
                Boost your business, improve process and make customer happy
                with our tailored software & product development services.
              </p>
              <nav className="nav mt-5">
                <a
                  href="/contact"
                  className="nav-link mr-3 btn btn btn-rounded btn-contrast"
                >
                  <i className="fas fa-tag mr-3" /> Contact us
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* Isometric Mockups */}
      <section className="isometric-mockups">
        <div className="tablet ipad landscape">
          <div className="screen">
            <img src={device1} alt="..." />
          </div>
          <div className="button" />
        </div>
        <div className="iphone light phone-big">
          <div className="screen">
            <img src={device2} alt="..." />
          </div>
          <div className="notch" />
        </div>
        <div className="iphone light phone-small">
          <div className="screen">
            <img src={device3} alt="..." />
          </div>
          <div className="notch" />
        </div>
        <div className="tablet ipad portrait">
          <div className="screen">
            <img src={device4} alt="..." />
          </div>
          <div className="button" />
        </div>
      </section>
      {/* Partners */}
      <section className="section alter3-partners bg-contrast edge top-left">
        <div className="container bring-to-front pb-md-9">
          <div className="row">
            <div className="col-md-6">
              <div className="section-heading">
                <i className="fas fa-handshake fa-3x accent mb-3" />
                <h3 className="text-alternate heading-line">
                  Great Software are made by good team, we build both
                </h3>
                <p className="lead">
                  Our expert Development teams specialize in the latest and most
                  in-demand technologies. Our engineers have guided numerous
                  clients, transforming projects from initial stages to
                  unparalleled success.
                </p>
              </div>
              <ul className="nav">
                <li className="nav-item mr-3 py-2 op-5">
                  <img src={logo1} className="icon" alt="" />
                </li>
                <li className="nav-item mr-3 py-2 op-5">
                  <img src={logo2} className="icon" alt="" />
                </li>
                <li className="nav-item mr-3 py-2 op-5">
                  <img src={logo3} className="icon" alt="" />
                </li>
                <li className="nav-item mr-3 py-2 op-5">
                  <img src={logo4} className="icon" alt="" />
                </li>
                <li className="nav-item mr-3 py-2 op-5">
                  <img src={logo5} className="icon" alt="" />
                </li>
                <li className="nav-item mr-3 py-2 op-5">
                  <img src={logo6} className="icon" alt="" />
                </li>
              </ul>
              <a
                href="/contact"
                className="more-link btn btn-outline-primary bold"
              >
                Connect us
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Powerful Features */}
      <section className="section alter3-features" id="features">
        <div className="shapes-container">
          <div className="shape shape-ring shape-ring-1">
            <div className="animation animation--rotating" />
          </div>
          <div className="shape shape-ring shape-ring-2">
            <div className="animation animation--rotating" />
          </div>
          <div className="shape shape-circle animation--clockwise">
            <div />
          </div>
          <div className="shape background-shape-main" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 order-lg-last">
              <div className="section-heading">
                <p className="px-2 text-alternate text-uppercase bold">
                  POWERFUL DEVELOPMENT TEAM AWAIT FOR YOU
                </p>
                <h2 className="heading-line">
                  Transform your business with specialized custom development
                  solutions.
                </h2>
                {/* <p className="lead text-muted my-4 semi-bold">
                  Laboris nisi ut aliquip ex ea commodo consequat.
                </p> */}
                <p>
                  Boost your business with our skilled development team. We
                  create custom solutions for growth. Use tailored development
                  to transform operations and achieve success. Join us for an
                  innovation.
                </p>
                <a
                  href="/contact"
                  className="more-link btn btn-primary text-contrast bold"
                >
                  Let Connect
                </a>
              </div>
            </div>
            <div className="col-lg-7 pr-lg-6">
              <div className="row">
                <div className="col-lg-6 rotated-cards">
                  <div data-aos="fade-down" data-aos-delay={0}>
                    <div className="card border-0 shadow mb-5 tilt">
                      <div className="card-body py-5 px-4">
                        <div className="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                          <i className="icon m-0 pe pe-7s-paint-bucket pe-3x" />
                        </div>
                        <h4 className="bold mb-5">Application Services</h4>
                        <p className="text-muted lead">
                          Back-End Solution <br />
                          Datebase Solution
                          <br />
                          API Development
                          <br />
                          Testing Solutions
                        </p>
                      </div>
                    </div>
                  </div>
                  <div data-aos="fade-down" data-aos-delay={500}>
                    <div className="card border-0 shadow mb-5 tilt">
                      <div className="card-body py-5 px-4">
                        <div className="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                          <i className="icon m-0 pe pe-7s-diamond pe-3x" />
                        </div>
                        <h4 className="bold mb-5">
                          Software Development(Custom)
                        </h4>
                        <p className="text-muted lead">
                          Web Applications
                          <br />
                          Mobile Applications
                          <br />
                          Product Engineering
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 rotated-cards">
                  <div data-aos="fade-down" data-aos-delay={800}>
                    <div className="card border-0 shadow mb-5 tilt">
                      <div className="card-body py-5 px-4">
                        <div className="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                          <i className="icon m-0 pe pe-7s-light pe-3x" />
                        </div>
                        <h4 className="bold mb-5">Enterprice Solutions</h4>
                        <p className="text-muted lead">
                          Staff Augmentation
                          <br />
                          ERP Solutions
                          <br />
                          Mobility Solutions
                          <br />
                          On-demand Services
                        </p>
                      </div>
                    </div>
                  </div>
                  <div data-aos="fade-down" data-aos-delay={1200}>
                    <div className="card border-0 shadow mb-5 tilt">
                      <div className="card-body py-5 px-4">
                        <div className="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                          <i className="icon m-0 pe pe-7s-cash pe-3x" />
                        </div>
                        <h4 className="bold mb-5">Infrastructure Management</h4>
                        <p className="text-muted lead">
                          DevOps Solutions
                          <br />
                          AWS Solution
                          <br />
                          Cloud & infrastructure
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Powered Design Blocks */}
      <section className="section powered-design" id="product">
        <div className="shapes-container">
          <div className="shape shape-circle shape-circle-1">
            <div data-aos="fade-up-left" data-aos-duration={1500} />
          </div>
          <div className="shape shape-circle shape-circle-2">
            <div
              data-aos="fade-up-left"
              data-aos-duration={1200}
              data-aos-delay={500}
            />
          </div>
          <div className="shape shape-ring animation--rotating-diagonal">
            <div />
          </div>
          <div className="shape shape-triangle shape-animated">
            <div className="animation--rotating" />
          </div>
          <div className="shape pattern-dots-1" />
        </div>
        <div className="container">
          <div className="row gap-y align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="section-heading">
                <h2 className="heading-line">
                  POWER YOUR WORK WITH OUR EXPERT DEVELOPERS
                </h2>
                <p className="lead text-muted">
                  "With a team of over 100+ skilled technology experts, we offer
                  comprehensive software development solutions, from inception
                  to project launch."
                </p>
              </div>
              <a
                href="/contact"
                className="btn btn-outline-alternate more-link mt-0"
              >
                Let’s Connect
              </a>
            </div>
            <div className="col-md-5">
              <div className="bocs-border">
                <figure >
                  <img src={meeting_image}  className="img-responsive" alt="" />
                </figure>
              </div>
              {/* <div className="rounded tilt overflow-hidden shadow-box shadow-hover bg-contrast mt-5">
                <figure>
                  <img src={pieces5} className="img-responsive" alt="" />
                </figure>
              </div> */}
        

              <a href="https://calendly.com/justmindwork2021/30min" className="thm-btn thm-btn__gradient mt-30">
                  <span className="btn-anim-wrap">
                    <span className="button-text">Scheduled a meeting</span>
                    <span className="button-text">Scheduled a meeting</span>
                  </span>
                  <i className="flaticon-top-right" />
                </a>
              <div className="rounded tilt overflow-hidden shadow-box shadow-hover bg-contrast mt-5">

                {/* <iframe
                  src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23EF6C00&ctz=Asia%2FKolkata&src=YXNodXBhZGh5YXk0OUBnbWFpbC5jb20&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZDY5ZjJmMjZiYzA2YmUyMzNlMDFkYjRmOGI5Mjg5Y2NkNjM3NWE0ODM4NDY1NDE5ZjU5NjI0NmM0ZDBmN2I0MUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4uaW5kaWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%2333B679&color=%23F6BF26&color=%230B8043"
                  style={{ borderWidth: 0 }}
                  // width={400}
                  className="w-100"
                  height={400}
                  frameBorder={0}
                  scrolling="no"
                /> */}

                {/* <figure>
                  <img src={pieces6} className="img-responsive" alt="" />
                </figure> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Features you can't miss */}
      <section className="section features-cant-miss">
        <div className="shapes-container overflow-clear">
          <div className="shape shape-circle shape-circle-1">
            <div data-aos="fade-up-left" />
          </div>
          <div className="shape shape-circle shape-circle-2">
            <div data-aos="fade-up-right" data-aos-delay={200} />
          </div>

          <div className="shape shape-circle shape-circle-4">
            <div data-aos="fade-up-left" data-aos-delay={600} />
          </div>
          <div className="shape shape-triangle shape-animated">
            <div className="animation--rotating" />
          </div>
        </div>
        <div className="container">
          <div className="row gap-y">
            <div className="col-md-6 order-md-last">
              <div className="section-heading">
                <i className="fas fa-trophy fa-2x text-danger mb-3" />
                <h2 className="heading-line">
                  We featured your Mobile App’s with idea you share us
                </h2>
              </div>
              <ul className="list-unstyled">
                <li className="media flex-column flex-md-row text-center text-md-left">
                  <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent fas fa-lightbulb pe-3x" />
                  <div className="media-body mt-3 mt-md-0">
                    <h5 className="bold mt-0 mb-1">Idea into Mobile Apps</h5>
                    <p className="m-0">
                      Share your vision, and we'll craft an advanced level app
                      tailored to your exact requirements.
                    </p>
                  </div>
                </li>
                <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                  <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe fas fa-mobile-alt pe-3x" />
                  <div className="media-body mt-3 mt-md-0">
                    <h5 className="bold mt-0 mb-1">
                      Swiftly Developed Mobile Apps
                    </h5>
                    <p className="m-0">
                      We swiftly develop Mobile Apps, ensuring compatibility for
                      both Android and iOS with a single codebase.
                    </p>
                  </div>
                </li>
                <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                  <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe fas fa-tools pe-3x" />
                  <div className="media-body mt-3 mt-md-0">
                    <h5 className="bold mt-0 mb-1">Quality Assurance</h5>
                    <p className="m-0">
                      Crafting flawless user experiences, we ensure your Mobile
                      application’s is bugs free, Secure and properly tested
                      before deployment.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <div className="bubble bubble-left center-x-md">
                <figure
                  className="rounded overflow-hidden shadow"
                  data-aos="zoom-in"
                >
                  <img src={pieces1} className="img-responsive" alt="" />
                </figure>
              </div>
              <figure className="bubble bubble-right rounded overflow-hidden shadow">
                <img
                  src={pieces2}
                  className="img-responsive"
                  alt=""
                  data-aos="fade-left"
                />
              </figure>
              <div className="iphone light">
                <div className="screen shadow-box">
                  <img src={pieces3} alt="..." />
                </div>
                <div className="notch" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Pricing */}
      <section className="section pricing-plans" id="pricing">
        <div className="shapes-container overflow-clear">
          <div className="shape shape-1" />
          <div className="shape shape-2" />
          <div className="shape shape-3" />
          <div className="shape shape-4" />
          <i className="icon pe pe-7s-cash pe-3x" data-aos="fade-down" />
          <i className="icon fas fa-dollar-sign fa-3x" data-aos="fade-up" />
          <i className="icon pe pe-7s-piggy pe-3x" data-aos="fade-up" />
          <i className="icon pe pe-7s-cart pe-3x" data-aos="fade-left" />
          <i className="icon fas fa-credit-card fa-3x" data-aos="fade-down" />
          <i className="icon fas fa-money-bill-alt fa-3x" data-aos="fade-up" />
        </div>
        <div className="container">
          <div className="section-heading text-center">
            <span className="rounded-pill shadow-box bold py-2 px-4">
              <i className="fas fa-lightbulb text-primary mr-2" />
              <span className="text-primary">Save more</span> with these awesome
              plans
            </span>
            <h2 className="mt-3 heading-line">Transparent pricing</h2>
            <p className="lead text-muted">
              Our services rates are as per your need,which will take your
              project to the next level. No hidden fees.
            </p>
          </div>
          <div className="row no-gutters align-items-center pricing-plans-options justify-content-around">
            <div className="col-lg-5 pricing-plan plan-basic order-md-first best-value">
              <div className="p-5">
                <div className="pl-3">
                  <h4 className="text-alternate bold text-capitalize">
                    Exclusive team.
                  </h4>
                </div>
                <hr />
                <div className=" d-md-flex">
                  <ul className="list-group list-group-flush list-group-no-border">
                    <li className="list-group-item">Dedicated Developers.</li>
                    <li className="list-group-item">Inhouse Developers.</li>
                    <li className="list-group-item">
                      Ratio-Above 95% project's success.
                    </li>
                    <li className="list-group-item">
                      Flexible-Available on all Time Zones.
                    </li>
                    <li className="list-group-item">
                      Available 40+ hours weekly
                    </li>
                    <li className="list-group-item">
                      Remote Working Facilitate
                    </li>
                    <li className="list-group-item">Adapting to your needs</li>
                    <li className="list-group-item">
                      Contractual development service
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-5 pricing-plan plan-basic order-md-first best-value">
              <div className="p-5">
                <div className="pl-3">
                  <h4 className="text-primary bold text-capitalize">
                    Select Developer Range
                  </h4>
                </div>
                <hr />
                <div className=" d-md-flex">
                  <ul className="list-group list-group-flush list-group-no-border">
                    <li className="list-group-item">Mid Level Developer</li>
                    <li className="list-group-item">Senior Level Developer</li>
                    <li className="list-group-item">Tech & Lead Developer</li>
                    <li className="list-group-item">
                      Share Requirement Documents
                    </li>
                    <li className="list-group-item">Get Quotation & Budget</li>
                    <li className="list-group-item">Milestones Based Report</li>
                    <li className="list-group-item">Trust Agreement(NDA)</li>
                    <li className="list-group-item">
                      Developer Starts Working
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-0 border-bottom">
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="text-center text-md-left">
                <h4 className="bold accent">Not sure what's better for you?</h4>
                <p className="mt-0">
                  Let's connect to explore how our expertise can enhance and add
                  value to your plans.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center text-md-right">
              <a
                href="/contact"
                className="btn btn-primary btn-lg text-contrast p-4 more-link ml-md-auto"
              >
                Connect us
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Integrations */}
      <section className="section integration-bubbles">
        <div className="container overflow-hidden">
          <div className="row">
            <div className="col-md-6">
              <div className="bubbles-wrapper">
                <div className="animations m-0">
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                    <img src={apx} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Autoprefixer
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                    <img src={bs} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Bootstrap
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                    <img src={cordova} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Cordova
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                    <img src={css} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">CSS</span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                    <img src={fa} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Fontawesome
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                    <img src={html} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      HTML
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                    <img src={js} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Javascript
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                    <img src={npm} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">Npm</span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                    <img src={sass} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      SASS
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                    <img src={apx} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Autoprefixer
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                    <img src={bs} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Bootstrap
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                    <img src={cordova} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Cordova
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                    <img src={css} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">CSS</span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                    <img src={fa} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Fontawesome
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                    <img src={html} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      HTML
                    </span>
                  </div>
                  <div className="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                    <img src={js} alt="" className="img-responsive" />
                    <span className="badge badge-contrast shadow-box">
                      Javascript
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="section-heading">
                <p className="text-alternate text-uppercase bold">
                  Plan with us
                </p>
                <h2 className="heading-line">
                  Share your vision, and we'll guide you in the right direction.
                </h2>
                <p className="lead text-muted">
                  If you're unsure about the right technology direction amidst
                  conflicting advice, let us guide you effectively
                </p>
              </div>
              <a href="/contact" className="more-link">
                Explore
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Why Choose Us */}
      <section className="section why-choose-us">
        <div className="shapes-container">
          <div className="pattern pattern-dots" />
        </div>
        <div className="container pb-8 bring-to-front">
          <div className="section-heading text-center">
            <h2 className="heading-line">
              Why you should Choose Just Mind Work Pvt. Ltd.?
            </h2>
            <p className="text-muted lead mx-auto">
              Our Just Mind Work developers and engineers stay current with the
              latest tech trends, boasting to over 15 years of experience. Count
              on us for top-quality work delivered on time, as we consistently
              give our utmost effort, making us the ideal choice for excellence.
            </p>
          </div>
          <div className="row gap-y">
            <div className="col-md-6">
              <h4 className="bold text-alternate">
                What Just Mind Work covers as the right agency.
              </h4>
              <p className="text-muted lead mb-5">
              15 years of tech brilliance in our 2-year-old journey – we're your top pick for modern solutions and digital success.
              </p>
              <ul className="list-unstyled why-icon-list">
                <li className="list-item">
                  <div className="media align-items-center">
                    <div className="icon-shape mr-3">
                      <div className="shape shape-pipes" />
                      <i className="icon text-alternate fas fa-industry fa-3x" />
                    </div>
                    <div className="media-body">
                      <h5 className="bold">Diversified Industries Working</h5>
                      <p className="my-0">
                      Global reach, industry-specific solutions. We build for everyone, perfectly.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="list-item">
                  <div className="media align-items-center">
                    <div className="icon-shape mr-3">
                      <div className="shape shape-pipes" />
                      <i className="icon text-alternate fas fa-wrench fa-3x" />
                    </div>
                    <div className="media-body">
                      <h5 className="bold">
                        Best Solutions & Customized Services
                      </h5>
                      <p className="my-0">
                      Innovate, customize, services. We create your dream app & web application through our expertise.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="list-item">
                  <div className="media align-items-center">
                    <div className="icon-shape mr-3">
                      <div className="shape shape-pipes" />
                      <i className="icon text-alternate fas fa-users fa-3x" />
                    </div>
                    <div className="media-body">
                      <h5 className="bold">Staff Augmentation Service</h5>
                      <p className="my-0">
                      Solved. Scale your team with our on-demand developer talent.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <hr className="my-5" />
              <div className="text-center text-md-left">
                <a href="/contact" className="btn btn-primary text-contrast">
                  Know More
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rotated-mockups device-twin">
                <div className="browser absolute shadow-lg">
                  <img src={tablet1} alt="..." />
                </div>
                <div className="front iphone light">
                  <div className="screen">
                    <img src={tablet1} alt="..." />
                  </div>
                  <div className="notch" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQs */}
      <section className="section faqs-dd bg-light edge top-left">
        <div className="shapes-container">
          <div className="absolute icon" data-aos="fade-down-left">
            <i className="fas fa-question" />
          </div>
        </div>
        <div className="container">
          <div className="section-heading text-center">
            <h2 className="heading-line">Frequently Asked Questions</h2>
            <p className="lead">Want to know more about our services?</p>
          </div>
          <div className="row">
            <div className="col-lg-8 mx-lg-auto">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                        What services does your IT company provide?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body card-body">
                    Our IT company offers a comprehensive range of services, including software development, IT consulting, and system integration.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Which technologies does your company specialize in?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body card-body">
                    We specialize in cutting-edge technologies such as artificial intelligence, blockchain, and cloud computing to provide innovative solutions for our clients.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How can I get in touch with your team for discussion ?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body card-body">
                    To discuss your needs or inquire about our services, please reach out to our team through our website's contact form or email us at business@justmindwork.com | sales@justmindwork.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Start For Free */}
      <section className="section start-free" id="trial">
        <div className="shapes-container">
          <i className="icon pe pe-7s-hourglass pe-3x" />
          <i className="icon pe pe-7s-magic-wand pe-3x" />
          <i className="icon pe pe-7s-rocket pe-3x" />
          <i className="icon pe pe-7s-plugin pe-3x" />
          <i className="icon pe pe-7s-like pe-3x" />
          <i className="icon pe pe-7s-clock pe-3x" />
          <i className="icon pe pe-7s-smile pe-3x" />
          <i className="icon pe pe-7s-piggy pe-3x" />
          <i className="icon pe pe-7s-shield pe-3x" />
          <i className="icon pe pe-7s-server pe-3x" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="section-heading text-center">
                <i className="pe pe-7s-unlock fa-3x text-alternate" />
                <h2 className="heading-line bold mt-4">
                  Hire Smarter and excellent developers with Our free test tasks
                  facility!
                </h2>
                <p className="lead text-muted">
                  Quickest onboarding achieved in just in few day! As all our
                  developers are direct employees of Just Mind Work, we maintain
                  full control over their deployment. With a 100% bench
                  resources, our developers are consistently available for
                  immediate working.
                </p>
              </div>
            </div>
          </div>
          <div className="nav flex-column flex-md-row align-items-center justify-content-center">
            <a
              href="/contact"
              className="btn btn-outline-dark btn-lg py-3 px-4 bold"
            >
              Contact us
            </a>
          </div>
        </div>
      </section>
      {/* Stay Tunned */}
    </main >
  );
};

export default Home;
