import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer";
import About from "./Components/SubPages/About";
import Portfolio from "./Components/SubPages/Portfolio";
import Services from "./Components/SubPages/Services";
import Contact from "./Components/SubPages/Contact";
import ServicesSingle from "./Components/SubPages/ServicesSingle";
import PortfolioSingles from "./Components/SubPages/PortfolioSingles";
import Product from "./Components/SubPages/Product";
import { ToastContainer } from "react-toastify";
import SubFooter from "./Components/SubFooter";

const App = () => {
  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfoliodetails/:slug" element={<PortfolioSingles />} />
          <Route path="/servicedetails/:slug" element={<ServicesSingle />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/product" element={<Product />} />

        </Routes>
        <SubFooter />
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;