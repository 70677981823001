import React, { useEffect, useState } from "react";
import port_single from "./img/portfolio/port_single.jpg"
import port_single_02 from "./img/portfolio/port_single_02.jpg"
import port_single_03 from "./img/portfolio/port_single_03.jpg"
import { useParams } from "react-router-dom";
import axios from "axios";
import parse from 'html-react-parser';


const PortfolioSingles = () => {
  const { slug } = useParams();
  console.log(slug);
  const [data, setdata] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://justmindwork.com/api/portfolios/${slug}`
      );
      setdata(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [slug]);
  return (
    <main>
      {/* breadcrumb start */}
      <section
        className="breadcrumb bg_img d-flex align-items-center dark-2"
        data-overlay="dark-2"
        data-opacity={7}
      >
        <div className="container p-0">
          <div className="breadcrumb__content text-center">
            <h2>Portfolio Details</h2>
            <ul className="bread-crumb clearfix ul_li_center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">Portfolio Details</li>
            </ul>
          </div>
        </div>
      </section>
      {/* breadcrumb end */}
      {/* portfolio single start */}
      <section className="portfolio-single pt-120 pb-120">
        <div className="container p-0">
          <div className="row mt-none-30 justify-content-center">
            <div className="col-lg-8 mt-30">
              <div className="portfolio-single__content">
                <div className="portfolio-single__thumb mb-30">
                  <img src={data?.image?.url} alt="" />
                </div>



              </div>
            </div>

          </div>
          <div className="content-managament">
          <h2>{data?.title}</h2>
            {typeof data?.description === 'string' ? parse(data?.description) : null}

          </div>
        </div>
      </section>
      {/* portfolio single end */}

    </main>
  );
};

export default PortfolioSingles;
