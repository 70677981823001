import React from "react";
import Servicesbox from "./Servicesbox";
import Testimonials from "./Testimonials";
import video_img from "./img/bg/video_img.jpg";
import srv_shape from "./img/shape/srv_shape.png";
const Services = () => {
  return (
    <>
      <section
        className="breadcrumb bg_img d-flex align-items-center dark-2"
        data-overlay="dark-2"
        data-opacity={7}
      >
        <div className="container p-0">
          <div className="breadcrumb__content text-center">
            <h2>Services</h2>
            <ul className="bread-crumb clearfix ul_li_center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">Services</li>
            </ul>
          </div>
        </div>
      </section>
      <Servicesbox />
      <section className="features pos-rel bg-deep pt-100 pb-120">
        <div className="container p-0">
          <div className="row">
            <div className="col-lg-6 mt-30">
              <div className="feature__video-img pos-rel">
                <img src={video_img} alt="" />
              </div>
            </div>
            <div className="col-lg-6 mt-30">
              <div className="feature__content2">
                <div className="section-heading section-heading--white mb-25">
                  <div className="heading-subtitle wow fadeInRight">
                    <img src="assets/img/icon/sh_icon.png" alt="" /> Why choose{" "}
                    <span>Invite</span>?
                  </div>
                  <h3 className="heading-title tx-split-text split-in-right mb-30">
                    We want to work the best!
                  </h3>
                  <p>
                    They usually show impact through before-and-after
                    comparisons or provide specific improvement statistics.
                  </p>
                </div>
                <ul className="service__list list-unstyled mt-30">
                  <li>
                    <i className="flaticon-checked" />
                    Best Service provider
                  </li>
                  <li>
                    <i className="flaticon-checked" />
                    Fully editable &amp; easy to use
                  </li>
                  <li>
                    <i className="flaticon-checked" />
                    Designed by talented team
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="feature__right-shape">
          <img src={srv_shape} alt="" />
        </div>
      </section>

      {/* <Testimonials /> */}
    </>
  );
};

export default Services;
