import React, { useEffect, useState } from "react";
import logo from "./JMW_PNG.png";
import logo1 from "./vb.png";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100;

      setIsScrolled(scrollPosition > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavToggle = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const headerClasses = `navbar navbar-expand-md main-nav navigation fixed-top sidebar-left ${
    isScrolled ? "scrolled" : ""
  }`;

  return (
    <nav className={headerClasses}>
      <div className="container p-0">
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleNavToggle}
        >
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
        <a href="/" className="navbar-brand">
          {isScrolled ? (
            <img src={logo1} alt="Logo1" className="logo " />
          ) : (
            <img src={logo} alt="JWM" className="logo " />
          )}
          {/* <img src={logo} alt="JWM" className="logo d-none d-md-block" /> */}
        </a>
        <div
          className={`collapse navbar-collapse ${isNavCollapsed ? "" : "show"}`}
          id="main-navbar"
        >
          <div className="sidebar-brand"></div>
          <ul className="nav navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link scrollto" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link scrollto" href="/about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link scrollto" href="/portfolio">
                Portfolio
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link scrollto" href="/services">
                Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link scrollto" href="/Contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
