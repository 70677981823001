import React, { useEffect, useState } from 'react'
import img_04 from "./img/services/img_04.jpg";
import img_05 from "./img/services/img_05.jpg";
import img_06 from "./img/services/img_06.jpg";
import img_07 from "./img/services/img_07.jpg";
import img_08 from "./img/services/img_08.jpg";
import img_09 from "./img/services/img_09.jpg";
import axios from 'axios';
import { Link } from 'react-router-dom';
const Servicesbox = () => {
  const [data, setdata] = useState([]);
  const fetchData = async () => {
    const response = await axios.get(`https://justmindwork.com/api/services`);
    setdata(response.data);
    console.log("response", response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <section className="service pt-120 pb-120">
      <div className="container p-0">
        <div className="row align-items-end mb-30">
          <div className="col-lg-7">
            <div className="section-heading mb-20">
              <div className="heading-subtitle wow fadeInRight">
                <img src="assets/img/icon/sh_icon.png" alt="" /> Best
                <span>service</span> in the town!
              </div>
              <h3 className="heading-title tx-split-text split-in-right">
                We combine strategy with marketing.
              </h3>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="section-heading mb-20">
              <p>
                We’ve grown up with the internet revolution, and we know how
                to deliver on its promise of improved business efficiency. We
                work throughout the London and the southeast.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-none-30">
          {data.map((location, index) => (
            <div className="col-lg-4 ol-md-6 col-sm-6 mt-30">
              <a
                href={`/servicedetails/${location.slug}`}
              >
                <div className="service-item-inner service-item-two">
                  <div className="item--image">
                    <img src={location.image.url} alt="" />
                  </div>
                  <div className="item--holder">
                    <div className="item--icon">
                      <i className={location.image_icon} />
                    </div>
                    <h3 className="item--title">
                      <a href="#">{location.title}</a>
                    </h3>
                    <div className="item--content">
                      {location.description.name}
                    </div>
                  </div>
                  <div className="item--holder-hover">
                    <div className="item--holder-top">
                      <div className="item--meta">
                        <div className="item--icon">
                          <i className={location.image_icon} />
                        </div>
                        <h3 className="item--title">
                          <a href={`/servicedetails/${location.slug}`} target="blank">
                            {location.title}
                          </a>
                        </h3>
                      </div>
                      <div className="item--image-small">
                        <img src="assets/img/services/img_sml_02.jpg" alt="" />
                      </div>
                    </div>
                    <div className="item--holder-bottom">
                      <div className="bottom-inner">
                        <div className="item--content">
                          {location.description.name}
                        </div>
                        <div className="item--readmore">
                          <a href={`/servicedetails/${location.slug}`} target="blank">
                            Read more
                            <i className="fas fa-long-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
       
        </div>
      </div>
    </section>
  )
}

export default Servicesbox